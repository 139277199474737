<h3>{{ 'GK.DYNAMIC_LIST.FILTER_CREATOR.FILTERS_HEADER' | translate }}</h3>
<div class="row">
  <div class="col-md-4 col-sm-6 col-xs-12 form-group">
    <label for="columnConfig">{{
      'GK.DYNAMIC_LIST.FILTER_CREATOR.COLUMN' | translate
    }}</label>
    <select
      class="form-select"
      id="columnConfig"
      [(ngModel)]="currentFilter.columnConfig"
      (ngModelChange)="initFilterCreator()"
      #columnConfig="ngModel"
    >
      @for (
        columnConfig of getColumnConfigsWithAvailableFilters();
        track columnConfig
      ) {
        <option [ngValue]="columnConfig">
          <gk-translated-label
            [configLabel]="columnConfig.controlConfig.label"
          ></gk-translated-label>
        </option>
        @if (columnConfig.isCommunityControl) {
          <option [ngValue]="districtColumnConfig">
            <gk-translated-label
              [configLabel]="'GK.MAP.DISTRICT' | translate"
            ></gk-translated-label>
          </option>
          <option [ngValue]="sheetColumnConfig">
            <gk-translated-label
              [configLabel]="'GK.MAP.SHEET' | translate"
            ></gk-translated-label>
          </option>
        }
      }
    </select>
  </div>

  <div class="col-md-4 col-sm-6 col-xs-12 form-group">
    <label class="invisible">{{
      'GK.DYNAMIC_LIST.FILTER_CREATOR.OPERATOR' | translate
    }}</label>
    <select
      class="form-select"
      attr.aria-label="{{
        'GK.DYNAMIC_LIST.FILTER_CREATOR.OPERATOR' | translate
      }}"
      id="operator"
      [(ngModel)]="currentFilter.operator"
      #operator="ngModel"
      [disabled]="!currentFilter?.columnConfig?.controlConfig?.label"
      (ngModelChange)="initRightOperand()"
    >
      @for (
        operator of currentFilter?.columnConfig?.availableOperators;
        track operator
      ) {
        <option [ngValue]="operator">
          {{ operator.name }}
        </option>
      }
    </select>
  </div>

  <div class="col-md-4 col-sm-6 col-xs-12 form-group">
    <div>
      @switch (currentFilter?.columnConfig?.controlConfig?.valueType) {
        @case (ValueType.Number) {
          <div>
            <label for="filterRightOperand">{{
              'GK.DYNAMIC_LIST.FILTER_CREATOR.VALUE' | translate
            }}</label>
            <input
              type="number"
              class="form-control"
              id="filterRightOperand"
              [(ngModel)]="currentFilter.rightOperand"
              [disabled]="!currentFilter?.operator"
            />
          </div>
        }
        @case (ValueType.Bool) {
          <div>
            <label for="filterRightOperand">{{
              'GK.DYNAMIC_LIST.FILTER_CREATOR.VALUE' | translate
            }}</label>
            <select
              class="form-select"
              id="filterRightOperand"
              [(ngModel)]="currentFilter.rightOperand"
              [disabled]="!currentFilter?.operator"
            >
              <option [value]="true">
                {{ 'GK.DYNAMIC_LIST.YES' | translate }}
              </option>
              <option [value]="false">
                {{ 'GK.DYNAMIC_LIST.NO' | translate }}
              </option>
            </select>
          </div>
        }
        @case (ValueType.Dict) {
          <div>
            <label for="filterRightOperand">{{
              'GK.DYNAMIC_LIST.FILTER_CREATOR.VALUE' | translate
            }}</label>
            <select
              class="form-select"
              id="filterRightOperand"
              [(ngModel)]="currentFilter.rightOperand"
              [disabled]="!currentFilter?.operator"
            >
              @for (field of currentDictionary.fields; track field) {
                <option [ngValue]="field.id">
                  {{ field.name }}
                </option>
              }
            </select>
          </div>
        }
        @case (ValueType.DateTime) {
          <div>
            <div>
              @switch (currentFilter?.operator?.filterOperatorType) {
                @case (FilterOperatorType.Between) {
                  <div>
                    <label for="filterRightOperandFrom">{{
                      'GK.DYNAMIC_LIST.FILTER_CREATOR.DATE_FROM' | translate
                    }}</label>
                    @if (currentFilter?.rightOperand) {
                      <div class="form-group">
                        <div class="input-group">
                          <input
                            class="form-control"
                            name="dpFrom"
                            id="filterRightOperandFrom"
                            [ngModel]="getFromValue()"
                            (ngModelChange)="setFromValue($event)"
                            ngbDatepicker
                            #dateFrom="ngbDatepicker"
                            [disabled]="!currentFilter?.operator"
                            attr.placeholder="{{
                              'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                            }}"
                          />
                          <button
                            class="btn btn-outline-secondary fa fa-calendar"
                            (click)="dateFrom.toggle()"
                            type="button"
                            [disabled]="!currentFilter?.operator"
                          ></button>
                        </div>
                      </div>
                    }
                    <label for="filterRightOperandTo">{{
                      'GK.DYNAMIC_LIST.FILTER_CREATOR.DATE_TO' | translate
                    }}</label>
                    @if (currentFilter?.rightOperand) {
                      <div class="form-group">
                        <div class="input-group">
                          <input
                            class="form-control"
                            name="dpTo"
                            id="filterRightOperandTo"
                            [ngModel]="getToValue()"
                            (ngModelChange)="setToValue($event)"
                            ngbDatepicker
                            #dateTo="ngbDatepicker"
                            [disabled]="!currentFilter?.operator"
                            attr.placeholder="{{
                              'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                            }}"
                          />
                          <button
                            class="btn btn-outline-secondary fa fa-calendar"
                            (click)="dateTo.toggle()"
                            type="button"
                            [disabled]="!currentFilter?.operator"
                          ></button>
                        </div>
                      </div>
                    }
                  </div>
                }
                @default {
                  <div>
                    <label for="filterRightOperand">{{
                      'GK.DYNAMIC_LIST.FILTER_CREATOR.DATE' | translate
                    }}</label>
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          class="form-control"
                          name="dp"
                          id="filterRightOperand"
                          [(ngModel)]="currentFilter.rightOperand"
                          ngbDatepicker
                          #d="ngbDatepicker"
                          [disabled]="!currentFilter?.operator"
                          attr.placeholder="{{
                            'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                          }}"
                        />
                        <button
                          class="btn btn-outline-secondary fa fa-calendar"
                          (click)="d.toggle()"
                          type="button"
                          [disabled]="!currentFilter?.operator"
                        ></button>
                      </div>
                    </div>
                  </div>
                }
              }
            </div>
          </div>
        }
        @case (ValueType.PlaceTypeahead) {
          <div>
            <label for="filterRightOperand">{{
              'GK.DYNAMIC_LIST.FILTER_CREATOR.VALUE' | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="filterRightOperand"
              [(ngModel)]="currentFilter.rightOperand"
              [ngbTypeahead]="searchPlace"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [disabled]="!currentFilter?.operator"
            />
          </div>
        }
        @case (ValueType.StreetTypeahead) {
          <div>
            <label for="filterRightOperand">{{
              'GK.DYNAMIC_LIST.FILTER_CREATOR.VALUE' | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="filterRightOperand"
              [(ngModel)]="currentFilter.rightOperand"
              [ngbTypeahead]="searchStreet"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [disabled]="!currentFilter?.operator"
            />
          </div>
        }
        @case (ValueType.LandParcelNumber) {
          <div>
            <label for="filterRightOperand">{{
              'GK.DYNAMIC_LIST.FILTER_CREATOR.VALUE' | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="filterRightOperand"
              [(ngModel)]="currentFilter.rightOperand"
              [disabled]="!currentFilter?.operator"
              #landParcelNumberControl="ngModel"
              [pattern]="landParcelNumberPattern"
            />
            @if (landParcelNumberControl.errors?.['pattern']) {
              <div class="alert alert-danger">
                {{
                  'GK.DYNAMIC_LIST.LAND_PARCEL_NUMBER_BAD_FORMAT_ERROR'
                    | translate
                }}
              </div>
            }
          </div>
        }
        @default {
          <div>
            <label for="filterRightOperand">{{
              'GK.DYNAMIC_LIST.FILTER_CREATOR.VALUE' | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="filterRightOperand"
              [(ngModel)]="currentFilter.rightOperand"
              [disabled]="!currentFilter?.operator"
              [placeholder]="
                currentFilter?.columnConfig?.controlConfig?.placeholder || ''
                  | translate
              "
            />
          </div>
        }
      }
    </div>
  </div>
</div>

<button
  class="btn btn-light mb-3"
  (click)="addFilter()"
  [disabled]="shouldDisableAddFilter()"
>
  {{ 'GK.DYNAMIC_LIST.FILTER_CREATOR.ADD_FILTER_BTN' | translate }}
</button>

@if (appliedfilters.length) {
  <div class="card w-100 my-3 p-2">
    <h5 class="card-title">
      {{ 'GK.DYNAMIC_LIST.ACTIVE_FILTERS' | translate }}
    </h5>
    <ul class="list-unstyled list-inline">
      @for (filter of appliedfilters; track filter) {
        <li class="list-inline-item">
          <button
            class="btn badge badge-light btn-color-from-db"
            (click)="removeFilter(filter)"
          >
            <span class="badge__label">{{ getFilterBadgeLabel(filter) }}</span>
          </button>
        </li>
      }
    </ul>
  </div>
}
