import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DocSignService,
  getFileNameFromResponseHeaders,
  SignedOrder,
} from '@gk/gk-modules';
import { FileSaverService } from 'ngx-filesaver';
import { takeWhile } from 'rxjs/operators';
import { NewRequestService } from '../../../services/new-request/new-request.service';

@Component({
  selector: 'app-new-request-summary',
  templateUrl: './new-request-summary.component.html',
  styleUrls: ['./new-request-summary.component.scss'],
})
export class NewRequestSummaryComponent implements OnInit, OnDestroy {
  private isAlive = true;
  recentlySignedOrder = {} as SignedOrder;

  constructor(
    private docSignService: DocSignService,
    private fileSaverService: FileSaverService,
    private newRequestService: NewRequestService,
  ) {}

  ngOnInit(): void {
    this.subscribeTorecentlySignedOrder();
  }

  subscribeTorecentlySignedOrder(): void {
    this.docSignService.recentlySignedOrder
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (submittedRequest) => (this.recentlySignedOrder = submittedRequest),
      );
  }

  getIncomingDocument(): void {
    this.newRequestService
      .downloadRequest(this.recentlySignedOrder.requestPUuid)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webRequestHttpResponse) => {
        const fileName = getFileNameFromResponseHeaders(
          webRequestHttpResponse.headers,
        );
        this.fileSaverService.save(webRequestHttpResponse.body, fileName);
      });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
