<div class="d-flex flex-column">
  <div
    [style.background-image]="backgroundUrlFromDb"
    [ngClass]="{
      'parallax-section-1': backgroundUrlFromDb,
      'content-footer': !backgroundUrlFromDb && webFooterLogoExists,
      'content-no-footer': !backgroundUrlFromDb && !webFooterLogoExists
    }"
  >
    <div class="backdrop">
      <div class="container pt-2">
        <div class="d-flex flex-row flex-wrap justify-content-center mask mx-2">
          <div
            class="col align-self-center text-center d-flex justify-content-center"
          >
            @if (mainPortalData?.logoUrl) {
              <img
                src="{{ mainPortalData?.logoUrl }}"
                class="main-portal-logo-max-width d-none d-md-block"
                alt="{{ mainPortalData?.name }}-logo"
              />
            }
          </div>
          <div class="col-8 mt-2">
            @if (webCmsCountiesHeaderText) {
              <div
                class="jumbotron jumbotron-fluid text-center pt-1 pb-0 mb-0 pok-jumbotron"
              >
                <div
                  [innerHTML]="webCmsCountiesHeaderText"
                  class="webCmsCountiesHeaderText"
                ></div>
              </div>
            }
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="container">
        <div class="d-flex flex-row flex-wrap justify-content-center">
          @for (webPortal of webPortalData; track webPortal.portalId) {
            @if (webPortal.show) {
              <li class="col-12 col-md-6 col-xl-4 px-0 web-portal-list">
                <a
                  (click)="handleWebPortalRedirect(webPortal)"
                  (keydown.enter)="handleWebPortalRedirect(webPortal)"
                  (focus)="portalCardHoverIds[webPortal.portalId] = true"
                  (blur)="portalCardHoverIds[webPortal.portalId] = false"
                  tabindex="0"
                  id="{{ webPortal.portalId }}"
                >
                  <div
                    class="card shadow-sm text-center m-2 pok-card"
                    (mouseover)="portalCardHoverIds[webPortal.portalId] = true"
                    (mouseleave)="
                      portalCardHoverIds[webPortal.portalId] = false
                    "
                    [ngStyle]="{
                      'border-color': webPortal.color,
                      'background-color':
                        portalCardHoverIds[webPortal?.portalId] === true
                          ? getPokCardBackgroundColor(pokCardOpacity.Hovered)
                          : getPokCardBackgroundColor(pokCardOpacity.Default)
                    }"
                  >
                    <div
                      class="card-header pok-card-header"
                      [ngStyle]="{
                        'background-color': webPortal.color,
                        opacity: !backgroundUrlFromDb
                          ? pokCardOpacity.Hovered
                          : portalCardHoverIds[webPortal?.portalId] === true
                            ? pokCardOpacity.Hovered
                            : pokCardOpacity.Default
                      }"
                    ></div>
                    <h5 class="card-title mb-0">
                      {{
                        getTranslatedWebPortalNameBySelectedInformationPortalTranslation(
                          webPortal
                        )
                      }}
                    </h5>
                    <div class="p-1 gk-scrollbar" tabindex="-1">
                      <p
                        class="card-text pok-card-text text-black"
                        [ngStyle]="{
                          opacity:
                            portalCardHoverIds[webPortal?.portalId] === true ||
                            !defaultThemeSelected
                              ? pokCardOpacity.Hovered
                              : pokCardOpacity.Default
                        }"
                      >
                        <small
                          [innerHTML]="
                            getTranslatedWebDescriptionNameBySelectedInformationPortalTranslation(
                              webPortal
                            )
                          "
                        ></small>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            }
          }
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar justify-content-center bg-color-from-db px-1">
    <div class="d-flex flex-row flex-wrap justify-content-center p-2">
      <button
        class="btn btn-color-from-db btn-lg m-2"
        (click)="goToInstructions()"
      >
        <em class="fa fa-book"></em>
        {{ getTranslateUsefulInfoNavInstructionKey() | translate }}
      </button>
      <button class="btn btn-color-from-db btn-lg m-2" (click)="goToFaq()">
        <em class="fa fa-question-circle"></em>
        {{
          'OFFICE_DEPARTMENTS_PORTAL.GEODESY.USEFUL_INFO_NAV.BUTTONS.FAQ'
            | translate
        }}
      </button>
      <button
        type="button"
        class="btn btn-color-from-db btn-lg m-2"
        (click)="showModalRequestsList()"
      >
        <em class="fa fa-file-pdf-o"></em>
        {{ getTranslateUsefulInfoNavRequestsKey() | translate }}
      </button>

      <button
        type="button"
        class="btn btn-color-from-db btn-lg m-2"
        (click)="showModalDocumentVerification()"
      >
        <em class="fa fa-check-circle"></em>
        {{ getTranslateUsefulInfoNavDocVerificationKey() | translate }}
      </button>
      <a
        class="btn btn-color-from-db btn-lg m-2 pt-3"
        [href]="getTeamViewerDownloadUrl()"
      >
        <div class="remote-help-icon"></div>
        {{ getTranslateUsefulInfoNavRemoteHelpKey() | translate }}
      </a>
      @if (
        systemConfigBlobGuestService.getAccessibilityStatementHtmlContent() ||
          systemConfigBlobGuestService.getAccessibilityMobileStatementHtmlContent()
          | async
      ) {
        <div
          class="btn btn-color-from-db btn-lg m-2 font-color-from-db dropdown"
        >
          <div
            class="btn btn-lg dropdown-toggle"
            role="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <em class="fa fa-universal-access"></em>
            {{
              getTranslateUsefulInfoNavAccessibilityStatementKey() | translate
            }}
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            @if (
              systemConfigBlobGuestService.getAccessibilityStatementHtmlContent()
                | async
            ) {
              <a
                class="btn btn-color-from-db w-100"
                href="#"
                target="_blank"
                [routerLink]="['/accessibility-statement-regular']"
                role="button"
                >{{ 'DESKTOP_APPLICATION' | translate }}</a
              >
            }
            @if (
              systemConfigBlobGuestService.getAccessibilityMobileStatementHtmlContent()
                | async
            ) {
              <a
                class="btn btn-color-from-db w-100"
                href="#"
                target="_blank"
                [routerLink]="['/accessibility-statement-mobile']"
                role="button"
                >{{ 'MOBILE_APPLICATION' | translate }}</a
              >
            }
          </div>
        </div>
      }
    </div>
  </nav>
</div>

@if (backgroundUrlFromDb) {
  <div
    [style.background-image]="backgroundUrlFromDb"
    class="parallax-section-2"
  ></div>
}

<app-modal-requests-list></app-modal-requests-list>
<app-modal-document-verification></app-modal-document-verification>
